import { loadBannerSet } from "components/BannerSet/BannerSetForm";
import CategoryForm from "components/Category/CategoryForm";
import { loadMasterTable } from "components/Mastertable/MasterTableForm";
import { productLoader } from "components/Product/ProductForm/EditProductForm";
import { loadRequestedSeller } from "components/RequestedSellers/RequestedSellerForm";
import EditReturns from "components/Returns/EditReturns";
import { loadSeller } from "components/Sellers/SellerForm";
import UpdateStore from "components/Stores/UpdateStore/UpdateStore";
import { loadBanner } from "components/SubBanners/BannerForm";
import { loadUser } from "components/Users/UserForm";
import { loadVoucher } from "components/Vouchers/VoucherForm";
import Layout from "layout";
import NotFound from "pages/404";
import AddPhoneNumber from "pages/AddPhoneNumber";
import BannerSet from "pages/BannerSet";
import Banners from "pages/Banners";
import Categories, { loadCategories, loadCategory } from "pages/Categories";
import Chat from "pages/Chat";
import Collection, { loadCollection } from "pages/Collection";
import Dashboard from "pages/Dashboard";
import FullOrders from "pages/FullOrders";
import { orderDetailsLoader } from "pages/FullOrders/OrderDetailsPage";
import GroupBuy, { loadGroupBuy } from "pages/GroupBuy";
import Insights from "pages/Insights";
import LoginPage from "pages/Login";
import MasterTable from "pages/MasterTable";
import Notifications from "pages/Notifications";
import Orders from "pages/Orders";
import { loadOrderDetails } from "pages/Orders/OrderDetailsPage";
import Products from "pages/Product";
import RecoverPassword from "pages/RecoverPassword";
import RequestedSellers from "pages/RequestedSellers";
import ResetPassword from "pages/ResetPassword";
import Returns, { loadReturns } from "pages/Returns";
import Sellers from "pages/Sellers";
import Settings from "pages/Settings";
import SignupPage from "pages/Signup";
import Store from "pages/Store";
import { loadSellerStore } from "pages/Store/UpdateStoreForm";
import Stores from "pages/Stores";
import Users from "pages/Users";
import Verification from "pages/Verification";
import Videos from "pages/Videos";
import Vouchers from "pages/Vouchers";
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import AdminRoute from "routes/AdminRoute";
import AuthRoute from "routes/AuthRoute";
import ProtectedRoute from "routes/ProtectedRoute";
import VerificationRoute from "routes/VerificationRoute";
import AuthPhoneNumberRoute from "./AuthPhoneNumberRoute";

const routes = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<Layout />}>
          <Route index element={<Dashboard />} />
          <Route path="dashboard">
            <Route index element={<Dashboard />} />
            <Route path="insights" element={<Insights />} />
          </Route>

          <Route path="products">
            <Route index element={<Products />} />
            <Route path="create" element={<Products create />} />
            <Route
              path="update/:id"
              element={<Products edit />}
              loader={productLoader}
            />
            <Route path="bulk-upload" element={<Products bulk />} />

            <Route path="group-buy" element={<AdminRoute />}>
              <Route index element={<GroupBuy />} />
              <Route path="create" element={<GroupBuy create />} />
              <Route
                path="update/:id"
                element={<GroupBuy edit />}
                loader={loadGroupBuy}
              />
            </Route>

            <Route path="collections" element={<AdminRoute />}>
              <Route index element={<Collection />} />
              <Route path="create" element={<Collection create />} />
              <Route
                path="update/:id"
                element={<Collection edit />}
                loader={loadCollection}
              />
              <Route path="add/:id" element={<Collection addProducts />} />
            </Route>

            <Route path="vouchers" element={<AdminRoute />}>
              <Route index element={<Vouchers />} />
              <Route path="create" element={<Vouchers create />} />
              <Route
                path="update/:id"
                element={<Vouchers update />}
                loader={loadVoucher}
              />
            </Route>

            <Route path="categories" element={<AdminRoute />}>
              <Route index element={<Categories />} loader={loadCategories} />
              <Route path="create" element={<CategoryForm create />} />
              <Route
                path="update/:id"
                element={<Categories edit />}
                loader={loadCategory}
              />
              <Route
                path=":parentId/subcategory"
                element={<Categories addSubCategory />}
              />
              <Route
                path=":lvl2"
                element={<Categories title="Categories" />}
                loader={loadCategories}
              />
              <Route
                path=":lvl2/:lvl3"
                element={<Categories title="Categories" />}
                loader={loadCategories}
              />
            </Route>
          </Route>

          <Route path="videos">
            <Route index element={<Videos />} />
            <Route path="upload" element={<Videos upload />} />
            <Route path="update/:id" element={<Videos edit />} />
            <Route path="create" element={<Videos create />} />
            <Route path=":slug/update" element={<Videos update />} />
          </Route>

          <Route path="orders">
            <Route index element={<Orders />} />
            <Route path="create" element={<Orders create />} />
            <Route
              path=":id"
              element={<Orders orderDetails />}
              loader={loadOrderDetails}
            />
            <Route path="full-orders">
              <Route index element={<FullOrders />} />
              <Route
                path=":id"
                element={<FullOrders orderDetails />}
                loader={orderDetailsLoader}
              />
            </Route>
            <Route path="returns">
              <Route index element={<Returns />} />
              <Route
                path="update/:id"
                element={<EditReturns />}
                loader={loadReturns}
              />
            </Route>
          </Route>

          <Route path="users">
            <Route index element={<Users />} />
            <Route path="create" element={<Users create />} />
            <Route
              path="update/:id"
              element={<Users update />}
              loader={loadUser}
            />
            <Route path="requested-sellers">
              <Route index element={<RequestedSellers />} />
              <Route
                path="update/:id"
                element={<RequestedSellers update />}
                loader={loadRequestedSeller}
              />
            </Route>
            <Route path="sellers">
              <Route index element={<Sellers />} />
              <Route
                path="update/:id"
                element={<Sellers update />}
                loader={loadSeller}
              />
            </Route>
          </Route>

          <Route path="content">
            <Route path="banner-set">
              <Route index element={<BannerSet title="Banner Set" />} />
              <Route path="create" element={<BannerSet create />} />
              <Route
                path=":id/update"
                element={<BannerSet update />}
                loader={loadBannerSet}
              />
              <Route path=":id/:device/banners">
                <Route index element={<Banners />} />
                <Route path="create" element={<Banners create />} />
                <Route
                  path=":id/update"
                  element={<Banners update />}
                  loader={loadBanner}
                />
              </Route>
            </Route>
          </Route>
          <Route path="chat" element={<Chat />} />

          <Route path="stores">
            <Route index element={<Stores />} />
            <Route path="update/:id" element={<Stores update />} />
          </Route>
          <Route path="store">
            <Route index element={<Store />} />
            <Route
              path="update"
              element={<UpdateStore />}
              loader={loadSellerStore}
            />
          </Route>
          <Route path="master-table">
            <Route index element={<MasterTable />} />
            <Route path="create" element={<MasterTable create />} />
            <Route
              path=":id/update"
              element={<MasterTable update />}
              loader={loadMasterTable}
            />
          </Route>

          <Route path="settings" element={<Settings />} />
          <Route path="notifications">
            <Route index element={<Notifications />} />
            {/* <Route path="create" element={<Notifications create />} /> */}
          </Route>
        </Route>
      </Route>

      <Route element={<AuthRoute />}>
        <Route path="login" element={<LoginPage />} />
        <Route path="signup" element={<SignupPage />} />
      </Route>

      <Route element={<VerificationRoute />}>
        <Route index path="verification" element={<Verification />} />
      </Route>

      <Route path="phone-number" element={<AuthPhoneNumberRoute />}>
        <Route index element={<AddPhoneNumber />} />
      </Route>

      <Route path="recover-password" element={<RecoverPassword />} />
      <Route path="reset-password" element={<ResetPassword />} />

      <Route path="*" element={<NotFound />} />
    </>
  )
);

export default routes;
