import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import BannerImageForm from "components/SubBanners/BannerForm/BannerImageForm";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import {
  addBanner,
  getBanner,
  resetBannerState,
  updateBanner,
  updateBannerImage,
} from "store/slices/bannersSlice";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";
import MobileBannerForm from "./MobileBannerForm";

function BannerForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const id = useParams().id;
  const device = useParams().device;
  const { banner, loading } = useSelector((state) => state.banners);

  const initialValues = useMemo(
    () => ({
      bannerName: banner?.name ?? "",
      url: banner?.url ?? "",
      bannerImage: banner?.image ?? "",
      linkId: banner?.linkId ?? "",
      linkType: banner?.type ?? "",
    }),
    [banner]
  );

  const handleSubmit = useCallback(
    (values) => {
      const formData = new FormData();
      formData.append("name", values.bannerName);
      formData.append("bannerSetId", id);
      formData.append("bannerImage", values.bannerImage);
      if (device === "web") {
        formData.append("url", values.url);
      } else {
        formData.append("linkId", values.linkId);
        formData.append("type", values.linkType);
      }
      if (!banner) {
        dispatch(addBanner({ formData }))
          .unwrap()
          .then(() => {
            toast.success("Banner added successfully");
            navigate(
              `/content/banner-set/${
                banner ? banner.bannerSetId : id
              }/${device}/banners`
            );
          })
          .catch(() => {
            toast.error("Something went wrong");
          });
      }

      const data = {};
      if (device === "web") {
        data.name = values.bannerName;
        data.url = values.url;
      } else {
        data.name = values.bannerName;
        data.linkId = values.linkId;
        data.type = values.linkType;
      }

      let formValues = { ...data };
      let bannerValues = { ...banner };
      let updatedValues = reduceObject(formValues, bannerValues);

      if (banner && Object.keys(updatedValues).length !== 0) {
        dispatch(updateBanner({ id, data: updatedValues }))
          .unwrap()
          .then(() => {
            toast.success("Updated successfully");
            navigate(
              `/content/banner-set/${
                banner ? banner.bannerSetId : id
              }/${device}/banners`
            );
          })
          .catch(() => {
            toast.error("Something went wrong");
          });
      }

      const imgFormData = new FormData();
      imgFormData.append("bannerImage", values.bannerImage);
      if (banner && values.bannerImage instanceof Object) {
        dispatch(updateBannerImage({ id, data: imgFormData }))
          .unwrap()
          .then(() => {
            toast.success("Updated successfully");
            navigate(
              `/content/banner-set/${
                banner ? banner.bannerSetId : id
              }/${device}/banners`
            );
          })
          .catch(() => {
            toast.error("Something went wrong");
          });
      }

      navigate(
        `/content/banner-set/${
          banner ? banner.bannerSetId : id
        }/${device}/banners`
      );
    },
    [banner, device, dispatch, id, navigate]
  );

  useEffect(() => {
    return () => dispatch(resetBannerState());
  }, [dispatch]);

  return (
    <Box py={2} px={1}>
      <Box display="flex" alignItems="center">
        <Box paddingTop={2} paddingBottom={1} px={1}>
          <CustomBreadcrumbs />
        </Box>
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        py={1}
        mb={2}
      >
        {banner ? `Update Banner ${banner?.name}` : "Add Banner"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema(device)}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <StyledMuiCard
              title="Add Banner"
              subTitle="Please enter the basic information of the banner such as name , url"
            >
              <CardContent>
                <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                  <Grid item xs={6} lg={6}>
                    <Box display="flex" flexDirection="column" gap={2}>
                      <Typography
                        variant="h3"
                        fontWeight="bold"
                        fontSize="1.25rem"
                        color="text.secondary"
                      >
                        Banner Name
                      </Typography>
                      <StyledTextField
                        fullWidth
                        type="text"
                        {...formik.getFieldProps("bannerName")}
                        error={Boolean(
                          formik.touched.bannerName && formik.errors.bannerName
                        )}
                        helperText={
                          formik.touched.bannerName &&
                          !!formik.errors.bannerName
                            ? formik.errors.bannerName
                            : "Enter a descriptive name"
                        }
                      />
                    </Box>
                  </Grid>
                  {device === "web" ? (
                    <Grid item xs={6} lg={6}>
                      <Box display="flex" flexDirection="column" gap={2}>
                        <Typography
                          variant="h3"
                          fontWeight="bold"
                          fontSize="1.25rem"
                          color="text.secondary"
                        >
                          Url
                        </Typography>
                        <StyledTextField
                          fullWidth
                          type="text"
                          pattern="[A-Za-z0-9]+"
                          {...formik.getFieldProps("url")}
                          error={Boolean(
                            formik.touched.url && formik.errors.url
                          )}
                          helperText={
                            formik.touched.url && !!formik.errors.url
                              ? formik.errors.url
                              : "Enter a valid URL"
                          }
                        />
                      </Box>
                    </Grid>
                  ) : (
                    <MobileBannerForm formik={formik} />
                  )}
                </Grid>
              </CardContent>
            </StyledMuiCard>
            <Grid item xs={12} lg={12} mt={3}>
              <BannerImageForm formik={formik} />
            </Grid>
            <Grid item xs={12} mt={3}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disableElevation
                disabled={loading}
                sx={{
                  fontWeight: "bold",
                  width: 100,
                  height: 40,
                }}
              >
                Submit
              </Button>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(BannerForm);

const validationSchema = (device) =>
  Yup.object().shape({
    bannerName: Yup.string()
      .trim()
      .required("Required*")
      .min(5, "Name should be at least 5 characters long")
      .max(50, "Name is too long"),

    url: Yup.string().when({
      is: () => device === "web",
      then: Yup.string()
        .required("required*")
        .matches(/^\S+$/, "Url can't contain empty spaces"),
      otherwise: Yup.string(),
    }),
    linkId: Yup.string().when({
      is: () => device === "mobile",
      then: Yup.string()
        .matches(/^\S*$/, "Link Id cannot contain blank spaces")
        .required("required*"),
      otherwise: Yup.string(),
    }),
    linkType: Yup.string().when({
      is: () => device === "mobile",
      then: Yup.string().required("required*"),
      otherwise: Yup.string(),
    }),
    bannerImage: Yup.mixed().required("Image is required"),
  });

export function loadBanner({ params }) {
  const bannerId = params?.id;
  store.dispatch(getBanner(bannerId ?? ""));
  return null;
}
