import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getErrorMessage, getResponseData } from "utils/helpers/apiDataHelpers";
import { toast } from "utils/hooks/useToast";
import http from "utils/httpRequest/http";
import httpv2 from "utils/httpRequest/httpv2";

export const getAdminProducts = createAsyncThunk(
  "products/getAdminProducts",
  async (data, { getState, rejectWithValue }) => {
    const { page, limit } = getState().products;
    const { userRole } = getState().auth;
    const url = userRole === "admin" ? "/products/admin" : `/products/seller`;
    try {
      const response = await httpv2.get(url, {
        params: {
          page: data?.page ?? page,
          limit: data?.limit ?? limit,
          sortBy: "-createdAt",
          ...data,
        },
      });
      const responseData = getResponseData(response);
      if (responseData?.results) {
        responseData.results?.forEach((item, index) => {
          const pageStart = (responseData.page - 1) * responseData.limit;
          item.serialNumber = pageStart + index + 1;
        });
      }
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const addFeaturedProduct = createAsyncThunk(
  "products/addFeaturedProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/products/featuredProducts`, {
        productId: data.id,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const removeFeaturedProduct = createAsyncThunk(
  "products/removeFeaturedProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.delete(
        `/products/featuredProducts/${data.id}`
      );
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const deleteBulkProducts = createAsyncThunk(
  "products/bulk",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.delete("/products/bulk", {
        data: data,
      });
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const bulkUploadProducts = createAsyncThunk(
  "products/bulkUploadProducts",
  async (data, { getState, rejectWithValue }) => {
    try {
      const response = await http.post(
        `products/bulk-upload/${getState().auth.id}`,
        data
      );
      const responseData = getResponseData(response);
      if (responseData?.status >= 400) {
        toast.error(responseData?.message);
        return rejectWithValue(responseData?.message);
      }
      toast.success("CSV file uploaded successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const getProductById = createAsyncThunk(
  "products/getProductById",
  async (id, { rejectWithValue }) => {
    try {
      // const products = getState().products.results;
      // const product = products.find((product) => product.id === id);
      // if (product) {
      //   return fulfillWithValue(product);
      // }
      const response = await http.get(`/products/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const updateBulkProducts = createAsyncThunk(
  "products/updateBulkProducts",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(`/products/bulk`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const getProductAfterVariantUpdate = createAsyncThunk(
  "products/getProductAfterVariantUpdate",
  async (id, { rejectWithValue }) => {
    try {
      const response = await http.get(`/products/${id}`);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(`/products`, data);
      const responseData = getResponseData(response);
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(
        message === "User not found" ? "The store does not exist" : message
      );
      return rejectWithValue(message);
    }
  }
);

export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.patch(
        `/products/${data.productId}`,
        data.body
      );
      const responseData = getResponseData(response);
      toast.success("Product updated successfully");
      return responseData;
    } catch (error) {
      const message = getErrorMessage(error);
      return rejectWithValue(message);
    }
  }
);

export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (productId, { getState, rejectWithValue }) => {
    const { results, page, limit } = getState().products;
    try {
      await http.delete(`/products/${productId}`);
      toast.success("Deleted successfully");
      let returnedProducts = results.filter((data) => data.id !== productId);
      if (returnedProducts) {
        returnedProducts = returnedProducts?.map((item, index) => {
          const pageStart = (page - 1) * limit;
          return {
            ...item,
            serialNumber: pageStart + index + 1,
          };
        });
      }
      return returnedProducts;
    } catch (error) {
      const message = getErrorMessage(error);
      toast.error(message);
      return rejectWithValue(message);
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    loading: true,
    productLoading: false,
    product: null,
    sellerId: "",
    results: [],
    page: 1,
    limit: 20,
    totalPages: 0,
    totalResults: 0,
    error: "",
    productError: "",
  },
  reducers: {
    // Actions
    resetState: (state) => {
      state.loading = true;
      state.productLoading = false;
      state.results = [];
      state.page = 1;
      state.limit = 20;
      state.totalPages = 0;
      state.totalResults = 0;
      state.error = null;
    },
    resetProductState: (state) => {
      state.productLoading = false;
      state.product = null;
      state.productError = null;
    },
    setStoreId: (state, action) => {
      state.product = { ...state.product, userId: action.payload };
      state.sellerId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminProducts.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getAdminProducts.fulfilled, (state, action) => {
        state.results = action.payload.results;
        state.totalResults = action.payload.totalResults;
        state.totalPages = action.payload.totalPages;
        state.page = action.payload.page;
        state.limit = action.payload.limit;
        state.loading = false;
      })
      .addCase(getAdminProducts.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(bulkUploadProducts.pending, (state) => {
        state.loading = true;
      })
      .addCase(bulkUploadProducts.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(bulkUploadProducts.rejected, (state) => {
        state.loading = false;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.results = action.payload;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(addProduct.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(updateBulkProducts.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(updateBulkProducts.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(updateBulkProducts.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(updateProduct.pending, (state) => {
        state.productLoading = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(getProductById.pending, (state) => {
        state.productLoading = true;
        state.productError = null;
      })
      .addCase(getProductById.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product = action.payload;
      })
      .addCase(getProductById.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      })
      .addCase(getProductAfterVariantUpdate.pending, (state) => {
        state.productLoading = true;
        state.productError = null;
      })
      .addCase(getProductAfterVariantUpdate.fulfilled, (state, action) => {
        state.productLoading = false;
        state.product.variants = action.payload?.variants ?? [];
      })
      .addCase(getProductAfterVariantUpdate.rejected, (state, action) => {
        state.productLoading = false;
        state.productError = action.payload;
      });
  },
});

// Action creators generated for each case reducer function
export const { resetState, resetProductState, setStoreId } =
  productsSlice.actions;

// Exporting default reducer
export default productsSlice.reducer;
