export default function filterNewCategories(categories) {
  return categories
    .filter(
      (category) =>
        category?.categorySpecs?.new ||
        (category.subCategories &&
          category.subCategories.some((sub) => sub?.categorySpecs?.new))
    )
    .map((category) => ({
      ...category,
      subCategories: category.subCategories
        ? filterNewCategories(category.subCategories)
        : [],
    }));
}
