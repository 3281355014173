import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import MuiAppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import Notifications from "components/Header/Notifications";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { logout } from "store/slices/authSlice";
import { SEARCH_ICON_COLOR } from "styles/colors";
import { toast } from "utils/hooks/useToast";

function Header({ handleDrawerOpen, isOpen }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userName = useSelector((state) => state.auth.fullName ?? "");
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState(SEARCH_OPTIONS.PRODUCT);
  const [open, setOpen] = useState(false);
  const draggedItem = useSelector((state) => state.categories.draggedItem);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelect = useCallback((selectValue) => {
    setSearchType(selectValue.target.value);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let _params = {};
      let url = "";

      switch (searchType) {
        case SEARCH_OPTIONS.PRODUCT:
          _params.name = "productName";
          url = "/products";
          break;
        case SEARCH_OPTIONS.VIDEO:
          _params.name = "title";
          url = "/videos";
          break;
        case SEARCH_OPTIONS.STORE:
          _params.name = "brandName";
          url = "/stores";
          break;
        case SEARCH_OPTIONS.GROUP_BUY:
          _params.name = "productName";
          url = "/products/group-buy";
          break;
        default:
          url = "/dashboard";
          break;
      }

      _params.value = searchValue;

      if (searchValue) {
        _params.page = 1;
        _params.limit = 20;
        navigate({
          pathname: url,
          search: createSearchParams(_params).toString(),
        });
      } else {
        navigate(url);
      }
    },
    [navigate, searchType, searchValue]
  );

  const handleLogout = useCallback(() => {
    handleClose();
    toast.success("You have been logged out");
    dispatch(logout());
    navigate("/");
  }, [dispatch, navigate]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (location.pathname === "/products") {
      const searchValueFromParams = searchParams.get("value") || "";
      setSearchValue(searchValueFromParams);
    } else {
      setSearchValue("");
    }
  }, [location]);

  return (
    <AppBar
      position="absolute"
      open={isOpen}
      sx={{
        backgroundColor: "background.default",
      }}
    >
      <Toolbar sx={{ backgroundColor: "#fff", padding: "0.5rem" }}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          sx={{
            ...(isOpen && { display: "none" }),
          }}
          onClick={handleDrawerOpen}
          size="large"
          color="primary.main"
        >
          <MenuIcon />
        </IconButton>
        <Search>
          <form
            onSubmit={handleSubmit}
            style={{
              flexGrow: 1,
              alignItems: "baseline",
            }}
          >
            <StyledInputBase
              fullWidth
              placeholder="What are you looking for today?"
              onChange={(e) => {
                setSearchValue(e.target.value.trimStart());
              }}
              disabled={draggedItem?.row}
              value={searchValue}
              startAdornment={
                <IconButton disabled>
                  <SearchIcon />
                </IconButton>
              }
            />
          </form>

          <Select
            onChange={handleSelect}
            sx={styledContainer}
            value={searchType}
            IconComponent={ExpandMoreIcon}
            disabled={draggedItem?.row}
          >
            {Object.values(SEARCH_OPTIONS).map((option) => (
              <MenuItem
                sx={{ fontSize: 16, fontWeight: "bold" }}
                key={option}
                dense
                value={option}
              >
                {option}
              </MenuItem>
            ))}
          </Select>
        </Search>
        <Notifications />
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            sx={{
              padding: "0 12px 0 12px",
            }}
          >
            <Avatar
              alt="Remy Sharp"
              src="/static/images/avatar/1.jpg"
              sx={{
                width: "3rem",
                height: "3rem",
                textTransform: "capitalize",
              }}
            >
              {userName.split(" ")[0][0]}
            </Avatar>
          </Box>
          <Box>
            <Typography
              color="#5e5873"
              fontWeight="bold"
              fontSize="1rem"
              width="max-content"
            >
              {userName}
            </Typography>
          </Box>
          <Box>
            <Select
              IconComponent={ExpandMoreIcon}
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              disabled={draggedItem?.row}
              MenuProps={{
                style: {
                  maxWidth: "auto",
                  maxHeight: 300,
                  position: "absolute",
                },
                disableScrollLock: true,
              }}
              sx={{
                boxShadow: "none",
                backgroundColor: "#fff",
                borderRadius: "0px",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                  boxShadow: "none",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  border: 0,
                },
                "& .MuiSelect-icon": {
                  fontSize: "2rem",
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  navigate("/settings");
                  handleClose();
                }}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Select>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;

Header.propTypes = {
  handleDrawerOpen: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    [theme.breakpoints.up("xl")]: {
      marginLeft: 370,
      width: `calc(100% - 370px)`,
    },
    [theme.breakpoints.down("xl")]: {
      marginLeft: 270,
      maxWidth: `calc(100% - 270px)`,
    },
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: SEARCH_ICON_COLOR,
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    transition: theme.transitions.create("width"),
    width: "100%",
    color: "#000000",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "2rem", // Adjust the icon size as needed
  },
}));

const styledContainer = {
  color: SEARCH_ICON_COLOR,
  height: "auto",
  borderLeft: "1px solid #e3eaf0 !important",
  borderRadius: "0px",
  "& .MuiOutlinedInput-notchedOutline": {
    border: 0,
    boxShadow: "none",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    border: 0,
  },
};

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  flexGrow: 1,
  border: "1px solid #e3eaf0 !important",
  height: "3rem",
  display: "flex",
  justifyContent: "space-between",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: "#E6F4FB",
  marginLeft: 2,
}));

const SEARCH_OPTIONS = {
  PRODUCT: "Products",
  STORE: "Stores",
  VIDEO: "Videos",
  GROUP_BUY: "Group-Buy",
};
