import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import ToggleButton from "components/ToggleButton";
import { Form, Formik } from "formik";
import CustomBreadcrumbs from "layout/BreadCrumbs";
import { memo, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { store } from "store/configureStore";
import {
  addBannerSet,
  getBannerSet,
  resetBannerSetState,
  updateBannerSet,
} from "store/slices/bannersSlice";
import {
  MOBILE_BANNER_LOCATION,
  WEB_BANNER_LOCATION,
} from "utils/constants/BannersLocation";
import reduceObject from "utils/helpers/reduceObject";
import { toast } from "utils/hooks/useToast";
import * as Yup from "yup";

function BannerSetForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { bannerSet, loading } = useSelector((state) => state.banners);

  const initialValues = useMemo(
    () => ({
      active: bannerSet?.active ?? false,
      device: bannerSet?.device ?? "",
      name: bannerSet?.bannerName ?? "",
      location: bannerSet?.location ?? "",
      type: bannerSet?.type ?? "",
    }),
    [bannerSet]
  );

  useEffect(() => {
    return () => dispatch(resetBannerSetState());
  }, [dispatch]);

  const handleSubmit = useCallback(
    (values) => {
      const data = {
        active: values.active,
        bannerName: values.name,
        device: values.device,
        location: values.location,
        type: values.type,
      };
      if (params.id) {
        let formValues = { ...data };
        let bannerValues = { ...bannerSet };
        let updatedValues = reduceObject(formValues, bannerValues);
        if (Object.keys(updatedValues).length === 0) {
          return navigate("/content/banner-set");
        } else {
          dispatch(updateBannerSet({ ...updatedValues, id: params.id }))
            .unwrap()
            .then(() => {
              toast.success("Updated Successfully");
              return navigate("/content/banner-set");
            })
            .catch(() => {
              toast.error("Failed to update");
            });
        }
      } else {
        dispatch(addBannerSet({ data }))
          .unwrap()
          .then((response) => {
            if (response?.isSuccess === true) {
              navigate(`/content/banner-set`);
            } else {
              toast.error(response?.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      }
    },
    [bannerSet, dispatch, navigate, params.id]
  );

  return (
    <Box py={2} px={1}>
      <Box paddingTop={2} paddingBottom={1} px={1}>
        <CustomBreadcrumbs />
      </Box>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.6rem" }}
        fontWeight={500}
        py={1}
        mb={2}
      >
        {params?.id
          ? `Update Banner Set ${bannerSet?.bannerName}`
          : "Add Banner Set"}
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Please Select an option"
                  subTitle="Choose an option where you want to upload banner"
                >
                  <CardContent>
                    <Stack
                      direction="column"
                      justifyContent="center"
                      gap={3}
                      p={{ xs: 1, md: 1.5 }}
                    >
                      <FormControl>
                        <RadioGroup
                          row
                          value={formik.values.device}
                          onChange={(e) =>
                            formik.setFieldValue("device", e.target.value)
                          }
                        >
                          {bannerSet?.device ? (
                            <FormControlLabel
                              value={bannerSet?.device}
                              control={<Radio />}
                              label="Mobile"
                            />
                          ) : (
                            <>
                              <FormControlLabel
                                value="mobile"
                                control={<Radio />}
                                label="Mobile"
                              />
                              <FormControlLabel
                                value="web"
                                control={<Radio />}
                                label="Website"
                              />
                            </>
                          )}
                        </RadioGroup>
                      </FormControl>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
            </Grid>
            {formik.values.device && (
              <Grid item xs={12} lg={12} mt={3}>
                <StyledMuiCard
                  title="Add Banner Set"
                  subTitle="Please enter the basic information of the banner such as name , location and type"
                  action={
                    <ToggleButton
                      checked={formik.values.active}
                      onChange={(e) =>
                        formik.setFieldValue("active", e.target.checked)
                      }
                    />
                  }
                >
                  <CardContent>
                    <Grid container spacing={2} p={{ xs: 1, md: 1.5 }}>
                      <Grid item xs={6} lg={6}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Banner Set Name
                          </Typography>
                          <StyledTextField
                            fullWidth
                            type="text"
                            {...formik.getFieldProps("name")}
                            error={Boolean(
                              formik.touched.name && formik.errors.name
                            )}
                            helperText={
                              formik.touched.name && !!formik.errors.name
                                ? formik.errors.name
                                : "Enter a descriptive name"
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={3} lg={3}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Location
                          </Typography>
                          <StyledTextField
                            fullWidth
                            select
                            type="text"
                            {...formik.getFieldProps("location")}
                            error={Boolean(
                              formik.touched.location && formik.errors.location
                            )}
                            helperText={
                              formik.touched.location &&
                              !!formik.errors.location
                                ? formik.errors.location
                                : "Select Banner Location"
                            }
                          >
                            {formik.values.device === "mobile"
                              ? MOBILE_BANNER_LOCATION.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))
                              : WEB_BANNER_LOCATION.map((name) => (
                                  <MenuItem key={name} value={name}>
                                    {name}
                                  </MenuItem>
                                ))}
                          </StyledTextField>
                        </Box>
                      </Grid>
                      <Grid item xs={3} lg={3}>
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Typography
                            variant="h3"
                            fontWeight="bold"
                            fontSize="1.25rem"
                            color="text.secondary"
                          >
                            Type
                          </Typography>
                          <StyledTextField
                            fullWidth
                            select
                            type="text"
                            {...formik.getFieldProps("type")}
                            error={Boolean(
                              formik.touched.type && formik.errors.type
                            )}
                            helperText={
                              formik.touched.type && !!formik.errors.type
                                ? formik.errors.type
                                : "Select Banner Type"
                            }
                          >
                            <MenuItem value="gallery">Gallery</MenuItem>
                            <MenuItem value="slider">Slider</MenuItem>
                            <MenuItem value="grid">Grid</MenuItem>
                          </StyledTextField>
                        </Box>
                      </Grid>
                    </Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      onClick={formik.handleSubmit}
                      sx={{
                        fontWeight: "bold",
                        width: 100,
                        height: 40,
                      }}
                      disabled={loading}
                    >
                      {params?.id ? "Update" : "Add"}
                    </Button>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
            )}
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(BannerSetForm);

const validationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .required("Required*")
    .min(5, "Name should be at least 5 characters long")
    .max(50, "Name is too long"),
  location: Yup.string().required("Required*"),
  type: Yup.string().required("Required*"),
});

export function loadBannerSet({ params }) {
  const bannerId = params?.id;
  store.dispatch(getBannerSet(bannerId ?? ""));
  return null;
}
