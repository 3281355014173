import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import { memo, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { orderShipment, updateOrderStatus } from "store/slices/ordersSlice";
import { UPDATE_ORDER_STATUS_OPTIONS } from "utils/constants/updateOrderStatusOptions";
import { toast } from "utils/hooks/useToast";

function OrderStatus() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const order = useSelector((state) => state.orders?.order);

  const handleChange = useCallback(
    (event) => {
      dispatch(
        updateOrderStatus({ name: event.target.value, order: params.id })
      )
        .unwrap()
        .then((res) => {
          toast.success("Order status changed successfully");

          const shipment = {
            order: params.id,
            seller: order.seller?.id,
            shippmentType: "intercity",
            shippmentMethod: "shyp",
          };

          if (res.name === "ready") {
            dispatch(orderShipment(shipment))
              .unwrap()
              .then(() => {
                toast.success("Order shipped successfully");
              })
              .catch(() => {
                toast.error("Failed to ship the order");
              });
          }

          navigate(`/orders/${order?.id}`);
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, navigate, order?.id, params.id]
  );

  return (
    <StyledMuiCard title="Order Status" subTitle="Please select order status">
      <CardContent>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <Select
              value={order?.orderStatus?.name ?? ""}
              onChange={handleChange}
            >
              {UPDATE_ORDER_STATUS_OPTIONS.map((option) => (
                <MenuItem
                  key={option.value}
                  dense
                  value={option.value}
                  disabled={
                    !option.isEnabled.includes(order?.orderStatus?.name ?? "")
                  }
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

export default memo(OrderStatus);
