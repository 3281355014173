import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import isValidNumber from "utils/helpers/isValidNumber";
function OfferDetailSection({ formik }) {
  return (
    <StyledMuiCard
      title="Offer Details"
      subTitle="Please enter the offer details like quantity, subscription of the product"
    >
      <CardContent>
        <Box display="flex" gap={4} py={2.5}>
          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Quantity
            </Typography>
            <StyledTextField
              name="quantity"
              type="text"
              value={formik.values.quantity}
              disabled={true}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(formik.touched.quantity && formik.errors.quantity)}
              helperText={
                formik.touched.quantity && !!formik.errors.quantity
                  ? formik.errors.quantity
                  : "Product quantity"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>
          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Limit
            </Typography>
            <StyledTextField
              name="limit"
              type="text"
              disabled={true}
              value={formik.values.limit}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(formik.touched.limit && formik.errors.limit)}
              helperText={
                formik.touched.limit && !!formik.errors.limit
                  ? formik.errors.limit
                  : "Product limit"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>
        </Box>
        <Box display="flex" gap={4} py={2.5}>
          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Minimum Subscription
            </Typography>
            <StyledTextField
              name="minSubscription"
              type="text"
              value={formik.values.minSubscription}
              disabled={!formik?.values?.productId}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
                formik.setFieldValue(
                  "limit",
                  Math.ceil(0.1 * formik.values.minSubscription)
                );
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(
                formik.touched.minSubscription && formik.errors.minSubscription
              )}
              helperText={
                formik.touched.minSubscription &&
                !!formik.errors.minSubscription
                  ? formik.errors.minSubscription
                  : "Minimum product subscription "
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>

          <Box display="flex" flexDirection="column" gap={2} flexGrow={1}>
            <Typography
              variant="h3"
              fontWeight="bold"
              fontSize="1.25rem"
              color="text.secondary"
            >
              Maximum Subscription
            </Typography>
            <StyledTextField
              name="maxSubscription"
              type="text"
              value={formik.values.maxSubscription}
              disabled={!formik?.values?.productId}
              onChange={(e) => {
                isValidNumber(e.target.value) && formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              placeholder="0"
              py={2}
              error={Boolean(
                formik.touched.maxSubscription && formik.errors.maxSubscription
              )}
              helperText={
                formik.touched.maxSubscription &&
                !!formik.errors.maxSubscription
                  ? formik.errors.maxSubscription
                  : "Maximum product subscription"
              }
              sx={{
                "& .MuiOutlinedInput-input": {
                  lineHeight: 1,
                  fontSize: "2rem",
                  fontWeight: "bold",
                  color: "text.secondary",
                  height: "auto",
                  padding: "0.5rem 0.75rem",
                },
              }}
            />
          </Box>
        </Box>
      </CardContent>
    </StyledMuiCard>
  );
}

OfferDetailSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(OfferDetailSection);
