import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  sendNotification,
  sendNotificationToAll,
} from "store/slices/notificationsSlice";
import { getUsers } from "store/slices/usersSlice";
import useDebounce from "utils/hooks/useDebounceValue";
import * as Yup from "yup";
import NotificationMediaSection from "./NotificationMediaSection";

function NotificationForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector(
    (state) => state.notifications?.notificationLoading
  );
  const [searchValue, setSearchValue] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(null);
  const [selectedOption, setSelectedOption] = useState("send one");
  const deferredValue = useDebounce(searchValue, 700);
  const handleChange = useCallback((value) => {
    setSearchValue(value);
  }, []);

  useEffect(() => {
    dispatch(getUsers({ limit: 100 })).then((res) => {
      setFilteredUsers(
        res?.payload?.results.filter(
          (user) => user?.origin?.source === "customer-app"
        )
      );
    });
  }, [dispatch]);

  useEffect(() => {
    const data = {};
    if (deferredValue) {
      data.name = "fullname";
      data.value = deferredValue;
    }
    const timeOut = setTimeout(() => {
      dispatch(getUsers(data));
    }, 500);
    return () => clearTimeout(timeOut);
  }, [deferredValue, dispatch]);

  const initialValues = useMemo(
    () => ({
      title: "",
      description: "",
      user: null,
      image: "",
      metaLink: "",
      mainImage: "",
      notifySellers: false,
      notifyUsers: false,
    }),
    []
  );

  const handleSubmit = useCallback(
    async (values) => {
      if (selectedOption === "send one") {
        const formData = new FormData();
        if (values.user) {
          formData.append("payload[userId]", values.user.id);
          formData.append("payload[role]", values.user.role);
        }
        formData.append("data[title]", values.title);
        formData.append("data[body]", values.description);
        formData.append("notificationMeta[link]", values.metaLink);
        if (values.mainImage) {
          formData.append("notificationImage", values.mainImage);
        }
        dispatch(sendNotification(formData)).then((response) => {
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/notifications");
          }
        });
      } else if (selectedOption === "send multiple") {
        const formDataForAll = new FormData();
        formDataForAll.append("title", values.title);
        formDataForAll.append("body", values.description);
        formDataForAll.append("seller", values?.notifySellers);
        formDataForAll.append("customer", values?.notifyUsers);
        formDataForAll.append("data[url]", values.metaLink);
        if (values.mainImage) {
          formDataForAll.append("notificationImage", values.mainImage);
        }
        dispatch(sendNotificationToAll(formDataForAll)).then((response) => {
          if (response?.meta?.requestStatus === "fulfilled") {
            navigate("/notifications");
          }
        });
      }
    },
    [dispatch, navigate, selectedOption]
  );

  return (
    <Box py={1} px={1}>
      <Typography
        variant="h1"
        color="primary"
        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2.5rem" }}
        fontWeight="500"
        pb={3}
      >
        Send Notification
      </Typography>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Grid container spacing={4}>
              {/* Actions */}
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Please Select an option"
                  subTitle="Choose an option if you want to send one or to all users"
                >
                  <CardContent>
                    <Grid container sx={{ px: 2 }}>
                      <Grid item xs={12} md={7}>
                        <FormControl>
                          <RadioGroup
                            row
                            value={selectedOption}
                            onChange={(e) => setSelectedOption(e.target.value)}
                          >
                            <>
                              <FormControlLabel
                                value="send one"
                                control={<Radio />}
                                label="Single User"
                              />
                              <FormControlLabel
                                value="send multiple"
                                control={<Radio />}
                                label="Send To All"
                              />
                            </>
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        {selectedOption === "send multiple" && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            marginTop={{ xs: 2, md: 0 }}
                            spacing={0.5}
                          >
                            <Checkbox
                              name="notifyUsers"
                              checked={formik.values.notifyUsers}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "notifyUsers",
                                  event.target.checked
                                );
                              }}
                            />
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Notify Users
                            </Typography>
                            <Checkbox
                              name="notifySellers"
                              checked={formik.values.notifySellers}
                              onChange={(event) => {
                                formik.setFieldValue(
                                  "notifySellers",
                                  event.target.checked
                                );
                              }}
                            />
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Notify Sellers
                            </Typography>
                          </Stack>
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledMuiCard>
              </Grid>
              <Grid item xs={12} lg={12}>
                <StyledMuiCard
                  title="Basic Information"
                  subTitle="Please enter the basic information of the notification such as title and description"
                >
                  <CardContent>
                    <Stack direction="row" gap={3} p={{ xs: 1, md: 1.5 }}>
                      <Grid container spacing={3}>
                        <Grid item xs={6} lg={6}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Title
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("title")}
                              error={Boolean(
                                formik.touched.title && formik.errors.title
                              )}
                              helperText={
                                formik.touched.title && !!formik.errors.title
                                  ? formik.errors.title
                                  : "Enter a descriptive title of the notification here"
                              }
                            />
                          </Stack>
                        </Grid>
                        {selectedOption === "send one" && (
                          <Grid item xs={6} lg={6}>
                            <Stack direction="column" gap={1}>
                              <Typography
                                variant="h3"
                                fontWeight="bold"
                                fontSize="1.25rem"
                                color="text.secondary"
                              >
                                User
                              </Typography>
                              <Autocomplete
                                name="User"
                                options={filteredUsers}
                                value={formik.values.user}
                                noOptionsText="No Options Available"
                                onChange={(_, user) => {
                                  formik.setFieldValue("user", user);
                                }}
                                getOptionLabel={(users) => users.fullname}
                                isOptionEqualToValue={(option, value) =>
                                  option.fullname === value.fullname
                                }
                                onBlur={formik.handleBlur}
                                renderInput={(params) => (
                                  <StyledTextField
                                    fullWidth
                                    onChange={(e) => {
                                      handleChange(e.target.value);
                                    }}
                                    autoFocus
                                    {...params}
                                    label="Select User"
                                    error={Boolean(
                                      formik.touched.user && formik.errors.user
                                    )}
                                    helperText={
                                      formik.touched.user &&
                                      !!formik.errors.user
                                        ? formik.errors.user
                                        : "Select the specific user whom you want to notify"
                                    }
                                    InputProps={{
                                      ...params.InputProps,
                                    }}
                                  />
                                )}
                              />
                            </Stack>
                          </Grid>
                        )}
                        <Grid item xs={12} lg={12}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Redirect Link
                            </Typography>
                            <StyledTextField
                              fullWidth
                              type="text"
                              {...formik.getFieldProps("metaLink")}
                              error={Boolean(
                                formik.touched.metaLink &&
                                  formik.errors.metaLink
                              )}
                              helperText={
                                formik.touched.metaLink &&
                                !!formik.errors.metaLink
                                  ? formik.errors.metaLink
                                  : "Enter a link of the notification here"
                              }
                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={12} lg={12}>
                          <Stack direction="column" gap={1}>
                            <Typography
                              variant="h3"
                              fontWeight="bold"
                              fontSize="1.25rem"
                              color="text.secondary"
                            >
                              Description
                            </Typography>
                            <StyledTextField
                              fullWidth
                              multiline
                              minRows={4}
                              type="text"
                              {...formik.getFieldProps("description")}
                              error={Boolean(
                                formik.touched.description &&
                                  formik.errors.description
                              )}
                              helperText={
                                formik.touched.description &&
                                !!formik.errors.description
                                  ? formik.errors.description
                                  : "Enter a description of the notification here"
                              }
                            />
                          </Stack>
                        </Grid>
                      </Grid>
                    </Stack>
                  </CardContent>
                </StyledMuiCard>
              </Grid>

              {/* Notification Media Section */}
              <Grid item xs={12} lg={12}>
                <NotificationMediaSection formik={formik} />
              </Grid>

              <Grid item xs={12}>
                <Stack direction="row" gap={2}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={formik.isSubmitting || !formik.isValid || loading}
                    disableElevation
                    sx={{
                      fontWeight: "bold",
                      minWidth: { md: 100, lg: 175, xl: 250 },
                      height: { xs: 45, xl: 55 },
                    }}
                  >
                    {formik.isSubmitting || loading ? "Sending..." : "Send"}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default memo(NotificationForm);

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Required*").max(30, "Title is too long"),
  description: Yup.string()
    .trim()
    .required("Required*")
    .min(80, "Description must be at least 80 characters long")
    .max(3000, "Description is too long"),
  metaLink: Yup.string().trim().url("Invalid URL format").required("Required*"),
});
