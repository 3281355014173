import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import CurrencyFormatter from "components/CurrencyFormatter";
import PropTypes from "prop-types";
import { memo } from "react";
import { returnPaymentMethod } from "utils/helpers/returnPaymentMethod";

function SummaryTile({
  title,
  value,
  price,
  paymentMethod,
  divider,
  isUppercase,
  isBold,
  isPaid,
}) {
  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="body1">{title}</Typography>
        {isPaid || price ? (
          <Typography variant="body1">
            {isPaid ? (
              "Paid"
            ) : (
              <CurrencyFormatter
                number={value}
                fontWeight={isBold ? "bold" : "normal"}
              />
            )}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            fontWeight={isBold ? 700 : 400}
            textTransform={isUppercase ? "uppercase" : "none"}
          >
            {paymentMethod ? (
              returnPaymentMethod(value)
            ) : (
              <CurrencyFormatter
                number={value}
                fontWeight={isBold ? "bold" : "normal"}
              />
            )}
          </Typography>
        )}
      </Stack>
      {divider && <Divider variant="middle" sx={{ my: 1 }} />}
    </>
  );
}

SummaryTile.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  price: PropTypes.bool,
  paymentMethod: PropTypes.bool,
  divider: PropTypes.bool,
  isBold: PropTypes.bool,
  isUppercase: PropTypes.bool,
  isPaid: PropTypes.bool,
};

SummaryTile.defaultProps = {
  title: "",
  value: "",
  price: false,
  paymentMethod: false,
  divider: false,
  isBold: false,
  isUppercase: false,
  isPaid: false,
};

export default memo(SummaryTile);
