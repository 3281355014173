import CancelIcon from "@mui/icons-material/Cancel";
import { Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import StyledTextField from "components/authentication/StyledTextField";
import { Form, Formik } from "formik";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setStoreId } from "store/slices/productsSlice";
import { getStores } from "store/slices/storesSlice";
import { closeSelectProductStoreDialog } from "store/slices/uiSlice";
import useDebounce from "utils/hooks/useDebounceValue";
import * as Yup from "yup";

function SelectStoreDialog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isOpen = useSelector(
    (state) => state.UI.isSelectProductStoreDialogOpen
  );
  const { results } = useSelector((state) => state?.stores);
  const [searchValue, setSearchValue] = useState("");

  const deferredValue = useDebounce(searchValue, 700);

  const handleClose = useCallback(() => {
    dispatch(closeSelectProductStoreDialog());
  }, [dispatch]);
  const handleChange = useCallback((value) => {
    setSearchValue(value);
  }, []);
  const initialValues = useMemo(
    () => ({
      store: null,
    }),
    []
  );

  useEffect(() => {
    const data = {};
    if (deferredValue) {
      data.name = "brandName";
      data.value = deferredValue;
    }
    const timeOut = setTimeout(() => {
      dispatch(getStores(data));
    }, 500);
    return () => clearTimeout(timeOut);
  }, [deferredValue, dispatch]);

  const handleSubmit = useCallback(
    (values) => {
      dispatch(setStoreId(values?.store?.seller));
      dispatch(closeSelectProductStoreDialog());
      navigate("create");
    },
    [dispatch, navigate]
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": { minWidth: { lg: "1000px" } },
      }}
      PaperProps={{
        elevation: 0,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={5}
        bgcolor="primary.main"
        sx={{
          padding: "0.25rem 0 0.25rem 2rem",
        }}
      >
        <Stack direction="column">
          <Typography variant="h5" component="h1" color="white">
            Select Store
          </Typography>
          <Typography variant="body2" color="white">
            Select the store to add the products
          </Typography>
        </Stack>
        <IconButton onClick={handleClose} size="large">
          <CancelIcon sx={{ color: "white", fontSize: "2.5rem" }} />
        </IconButton>
      </Stack>
      <Formik
        enableReinitialize={true}
        validateOnBlur={true}
        validateOnChange={true}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <Stack direction="row" p={4} gap={2}>
              <Box width="100%">
                <Autocomplete
                  name="store"
                  options={results}
                  value={formik.values.store}
                  noOptionsText="No Options Available"
                  onChange={(_, store) => {
                    formik.setFieldValue("store", store);
                  }}
                  getOptionLabel={(stores) => stores.brandName}
                  isOptionEqualToValue={(option, value) =>
                    option.brandName === value.brandName
                  }
                  onBlur={formik.handleBlur}
                  renderInput={(params) => (
                    <StyledTextField
                      fullWidth
                      onChange={(e) => {
                        handleChange(e.target.value);
                      }}
                      autoFocus
                      {...params}
                      label="Select Store"
                      error={Boolean(
                        formik.touched.store && formik.errors.store
                      )}
                      helperText={
                        formik.touched.store && !!formik.errors.store
                          ? formik.errors.store
                          : "Select the store to add product"
                      }
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                />
              </Box>
              <Button
                type="submit"
                size="large"
                variant="outlined"
                sx={{
                  height: "3.5rem",
                  minWidth: 250,
                }}
                disabled={!formik.values.store}
              >
                Continue
              </Button>
            </Stack>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}

const validationSchema = Yup.object({
  store: Yup.object().required("Required*"),
});
export default memo(SelectStoreDialog);
