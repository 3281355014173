import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import StyledMuiCard from "components/Product/ProductForm/StyledMuiCard";
import StyledTextField from "components/Product/ProductForm/StyledTextField";
import PropTypes from "prop-types";
import { memo } from "react";
import { CURRENCY } from "utils/constants/constants";
import isValidNumber from "utils/helpers/isValidNumber";

function PriceSection({ formik }) {
  return (
    <StyledMuiCard
      title="Pricing"
      subTitle="Enter price and sale price of the product"
    >
      <CardContent>
        <Stack
          direction="column"
          justifyContent="center"
          gap={2}
          py={{ xs: 1, md: 1.5 }}
          paddingLeft={1.5}
        >
          <Typography
            variant="h3"
            fontWeight="bold"
            fontSize="1.25rem"
            color="text.secondary"
          >
            Price
          </Typography>
          <StyledTextField
            fullWidth
            name="price"
            type="text"
            value={formik?.values?.price}
            disabled={true}
            placeholder="0"
            onChange={(e) => {
              isValidNumber(e.target.value) && formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(formik.touched.price && formik.errors.price)}
            helperText={
              formik.touched.price && !!formik.errors.price
                ? formik.errors.price
                : "Product price"
            }
            py={2}
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
                textAlign: "right",
              },
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  color="text.disabled"
                  sx={{
                    fontSize: "1.25rem",
                    borderRight: "1px solid #00000038",
                    paddingRight: "1rem",
                  }}
                >
                  {CURRENCY}
                </Typography>
              ),
            }}
          />
          <Stack direction="row" py={1} justifyContent="space-between">
            <Box>
              <Typography
                variant="h3"
                fontWeight="bold"
                fontSize="1.25rem"
                color="text.secondary"
              >
                Group Sales Price
              </Typography>
              <Typography
                variant="body1"
                color="text.secondary"
                pt={1}
                fontSize="1rem"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  display: "-webkit-box !important",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  whiteSpace: "normal",
                }}
              >
                Enable this option if you want to put product on sales
              </Typography>
            </Box>
          </Stack>
          <StyledTextField
            fullWidth
            name="groupSalePrice"
            type="text"
            placeholder="0"
            value={formik?.values?.groupSalePrice}
            onChange={(e) => {
              isValidNumber(e.target.value) && formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            disabled={formik.values.id || !formik?.values?.productId}
            error={Boolean(
              formik.touched.groupSalePrice && formik.errors.groupSalePrice
            )}
            helperText={
              formik.touched.groupSalePrice && !!formik.errors.groupSalePrice
                ? formik.errors.groupSalePrice
                : "Sales price must be lower than the regular price"
            }
            py={2}
            sx={{
              "& .MuiOutlinedInput-input": {
                lineHeight: 1,
                fontSize: "2rem",
                fontWeight: "bold",
                color: "text.secondary",
                height: "auto",
                padding: "0.5rem 0.75rem",
                textAlign: "right",
              },
            }}
            InputProps={{
              startAdornment: (
                <Typography
                  color="text.disabled"
                  sx={{
                    fontSize: "1.25rem",
                    borderRight: "1px solid #00000038",
                    paddingRight: "1rem",
                  }}
                >
                  {CURRENCY}
                </Typography>
              ),
            }}
          />
        </Stack>
      </CardContent>
    </StyledMuiCard>
  );
}

PriceSection.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default memo(PriceSection);
